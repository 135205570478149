import React, { useState } from 'react'
import PropTypes from 'prop-types'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import RmTooltip from './RmTooltip.jsx'
import RmCheckbox from './RmCheckbox.jsx'

const SectionApprovalPanel = ({
  annualReport,
  sectionId,
  onChange,
  isApprovableText,
  approveCheckboxText,
  disabled,
  selectedTabLintErrors,
}) => {
  const abortSignal = useAbortSignal()
  const currentApprovalObj = annualReport.sectionApprovals.find((appr) => appr.sectionId === sectionId)
  const [isApproved, setIsApproved] = useState(currentApprovalObj ? currentApprovalObj.isApproved : false)

  return (
    <div>
      {selectedTabLintErrors.length === 0 && <div>{isApprovableText}</div>}
      <RmTooltip title="Denna årsredovisning är låst." visible={annualReport.locked}>
        <RmCheckbox
          checked={isApproved}
          onChange={(checked) => {
            setIsApproved(checked)
            const newSectionApprovals = annualReport.sectionApprovals
              .filter((appr) => appr.sectionId !== sectionId)
              .concat([
                {
                  sectionId,
                  isApproved: checked,
                },
              ])
            API.saveAnnualReportValue(
              annualReport.reportId,
              'sectionApprovals',
              newSectionApprovals,
              abortSignal,
              onChange,
              { immediate: true }
            )
          }}
          disabled={disabled}
          className={`automation-approve-checkbox-${sectionId}`}
          label={approveCheckboxText}
        />
      </RmTooltip>
    </div>
  )
}

SectionApprovalPanel.propTypes = {
  annualReport: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isApprovableText: PropTypes.string,
  approveCheckboxText: PropTypes.string,
  disabled: PropTypes.bool,
  selectedTabLintErrors: PropTypes.array.isRequired,
}

SectionApprovalPanel.defaultProps = {
  isApprovableText: 'Sidan är klar att godkännas.',
  approveCheckboxText: 'Jag har kontrollerat sidan och godkänner',
  disabled: false,
}

export default SectionApprovalPanel
