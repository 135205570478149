import { appFetch } from './AppFetch.js'
import { debounced } from '../shared-universal/Utils.js'

async function startEmailConfirmationFlow(abortSignal) {
  await appFetch(`/api/request-email-confirmation`, {
    method: 'POST',
    signal: abortSignal,
  })
}

async function uploadFileToUrl(abortSignal, fileObj, targetUrl) {
  const { status, responseJson } = await appFetch(targetUrl, {
    signal: abortSignal,
    method: 'POST',
    headers: fileObj
      ? {
          'Content-Type': fileObj.type.split(';')[0] || 'application/octet-stream',
        }
      : {},
    ...(fileObj ? { body: fileObj } : {}),
  })
  if (status !== 200) {
    throw Error(`received http ${status} response code from ${targetUrl}`)
  }
  return responseJson
}

async function uploadUserImage(abortSignal, imageFileObj) {
  const result = await uploadFileToUrl(abortSignal, imageFileObj, '/api/authp/set-user-image')
  return result.fileId
}

async function unsetUserImage(abortSignal) {
  return appFetch('/api/authp/unset-user-image', {
    signal: abortSignal,
    method: 'POST',
  })
}

async function sieReimport(abortSignal, annualReportId, sieFileObj) {
  return uploadFileToUrl(
    abortSignal,
    sieFileObj,
    `/api/up/sie-reimport?filename=${sieFileObj.name}&annualReportId=${annualReportId}`
  )
}

async function createAnnualReport(abortSignal, sieFileObj, orgNr, email) {
  const params = new URLSearchParams()
  if (typeof email === 'string') {
    params.append('email', email)
  }
  params.append('withSie', Boolean(sieFileObj))
  if (sieFileObj) {
    params.append('filename', sieFileObj.name)
  }
  if (orgNr) {
    params.append('orgNr', orgNr)
  }
  return uploadFileToUrl(abortSignal, sieFileObj, `/api/create-annual-report?` + params.toString())
}

const submitSaveAnnualReport = (annualReportValuesMap, abortSignal, afterSaveCallback) => {
  return appFetch('/api/up/save-annual-report-values', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify(annualReportValuesMap),
  }).then((resp) => {
    afterSaveCallback()
    return resp
  })
}

let pendingAnnualReportValuesMap = {}
const debouncedSubmitSaveAnnualReport = debounced(500, (abortSignal, afterSaveCallback) => {
  const response = submitSaveAnnualReport(pendingAnnualReportValuesMap, abortSignal, afterSaveCallback)
  pendingAnnualReportValuesMap = {}

  return response
})

let pendingAnnualReportValuesMapImmediate = {}
const immediateSubmitSaveAnnualReport = debounced(0, (abortSignal, afterSaveCallback) => {
  const response = submitSaveAnnualReport(pendingAnnualReportValuesMapImmediate, abortSignal, afterSaveCallback)
  pendingAnnualReportValuesMapImmediate = {}

  return response
})

const saveAnnualReportValue = (annualReportId, key, value, abortSignal, afterSaveCallback = () => {}, opts = {}) => {
  if (opts.immediate) {
    pendingAnnualReportValuesMapImmediate.annualReportId = annualReportId
    pendingAnnualReportValuesMapImmediate[key] = value
    immediateSubmitSaveAnnualReport(abortSignal, afterSaveCallback)
  } else {
    pendingAnnualReportValuesMap.annualReportId = annualReportId
    pendingAnnualReportValuesMap[key] = value
    debouncedSubmitSaveAnnualReport(abortSignal, afterSaveCallback)
  }
}

let foretradareIdToForetradareValuesMap = {}
const debouncedSubmitSaveOperations = debounced(500, (abortSignal, afterSaveCallback) => {
  const valuesMapToSubmit = foretradareIdToForetradareValuesMap
  foretradareIdToForetradareValuesMap = {}
  return appFetch('/api/up/save-foretradare', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify(valuesMapToSubmit),
  }).then((resp) => {
    afterSaveCallback()
    return resp
  })
})

const saveForetradareValues = (foretradareId, foretradareKeyVals, abortSignal, afterSaveCallback = () => {}) => {
  if (!Object.hasOwn(foretradareIdToForetradareValuesMap, foretradareId)) {
    foretradareIdToForetradareValuesMap[foretradareId] = {}
  }
  for (let keyValEntry of foretradareKeyVals) {
    foretradareIdToForetradareValuesMap[foretradareId][keyValEntry.key] = keyValEntry.value
  }
  debouncedSubmitSaveOperations(abortSignal, afterSaveCallback)
}

const saveForetradareValue = (foretradareId, key, value, abortSignal, afterSaveCallback = () => {}) => {
  saveForetradareValues(foretradareId, [{ key, value }], abortSignal, afterSaveCallback)
}

const reapplyGrunduppgifter = (annualReportId, abortSignal, afterSaveCallback = () => {}) => {
  return appFetch('/api/up/reapply-grunduppgifter', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ reportId: annualReportId }),
  }).then((resp) => {
    afterSaveCallback()
    return resp
  })
}

const mapAccount = (financialYearId, account, targetAccount, abortSignal) => {
  return appFetch('/api/up/map-account', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ financialYearId, account, targetAccount }),
  })
}

const startEmailLogin = (email, abortSignal) => {
  return appFetch('/api/start-email-login', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  })
}

const lamnaInArsredovisning = async (annualReportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/lamna-in', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ annualReportId }),
  })
  return responseJson
}

const accepteraAvtal = async (annualReportId, bolagsverketAvtalstextDatum, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/acceptera-avtal', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ annualReportId, bolagsverketAvtalstextDatum }),
  })
  return responseJson
}

const lamnaInInk2 = async (annualReportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/lamna-in-ink2', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ annualReportId }),
  })
  return responseJson
}

const setInk2SignButtonClicked = async (inlamningsId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/set-sign-button-clicked', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ inlamningsId }),
  })
  return responseJson
}

const setMarkedAsSigned = async (inlamningsId, markedAsSigned, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/set-marked-as-signed', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ inlamningsId, markedAsSigned }),
  })
  return responseJson
}

const unlockReport = async (annualReportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/unlock-report', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ annualReportId }),
  })
  return responseJson
}

const saveTAction = async (reportId, actionName, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/save-taction', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ reportId, actionName }),
  })
  return responseJson
}

const dismissMessage = async (annualReportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/dismiss-message', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ annualReportId }),
  })
  return responseJson
}

const deleteReport = (reportId, abortSignal) => {
  return appFetch('/api/up/delete-report', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      reportId,
    }),
  })
}

const signingPrepare = async (reportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/signing-prepare', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      reportId,
    }),
  })
  return responseJson
}

const refreshSignatureStatus = async (reportId, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/refresh-signature-status', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      reportId,
    }),
  })
  return responseJson
}

const sendSignLinkTo = async (reportId, foretradareId, isRevisor, signLinkEmail, abortSignal) => {
  const { responseJson } = await appFetch('/api/up/send-signature-link', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      reportId,
      foretradareId,
      isRevisor,
      signLinkEmail,
    }),
  })
  return responseJson
}

export default {
  startEmailConfirmationFlow,
  uploadUserImage,
  unsetUserImage,
  sieReimport,
  createAnnualReport,
  saveAnnualReportValue,
  saveForetradareValue,
  reapplyGrunduppgifter,
  mapAccount,
  startEmailLogin,
  lamnaInArsredovisning,
  lamnaInInk2,
  setInk2SignButtonClicked,
  setMarkedAsSigned,
  accepteraAvtal,
  unlockReport,
  saveTAction,
  dismissMessage,
  deleteReport,
  signingPrepare,
  refreshSignatureStatus,
  sendSignLinkTo,
}
