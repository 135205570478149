import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/index.js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import sv from 'dayjs/locale/sv.js'
import AppMain from './AppMain.jsx'
import { UserConfigProvider } from '../ClientSideUserConfig.jsx'
import MainThemeProvider from './MainThemeProvider.jsx'

const App = (props) => {
  return (
    <LocalizationProvider adapterLocale={sv} dateAdapter={AdapterDayjs}>
      <MainThemeProvider>
        <UserConfigProvider>
          <AppMain />
        </UserConfigProvider>
      </MainThemeProvider>
    </LocalizationProvider>
  )
}

export default App
