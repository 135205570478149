import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import MenuItem from '@mui/material/MenuItem/index.js'

import RmSelect from './RmSelect.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import { REVBER_MODIFIED_OPTIONS } from '../../../shared-universal/AnnualReportUtils.js'

const ModifieradRevisionsberattelseEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()

  return (
    <React.Fragment>
      <div>Revisionsberättelse med modifierade uttalanden</div>
      <Box mt={1} />
      <RmSelect
        displayEmpty
        defaultValue={annualReport.revberModified ?? 'none'}
        onChange={(newRevberModified) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'revberModified',
            newRevberModified === 'none' ? null : newRevberModified,
            abortSignal,
            onChange,
            {
              immediate: true,
            }
          )
        }}
        disabled={disabled}
      >
        <MenuItem value={'none'}>Revisionsberättelse utan modifierade uttalanden</MenuItem>
        {REVBER_MODIFIED_OPTIONS.map((revberItemInfo) => {
          return (
            <MenuItem key={revberItemInfo.id} value={revberItemInfo.id}>
              {revberItemInfo.title}
            </MenuItem>
          )
        })}
      </RmSelect>
    </React.Fragment>
  )
}

ModifieradRevisionsberattelseEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ModifieradRevisionsberattelseEditor
