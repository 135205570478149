import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@mui/material'

const RmCheckbox = ({ label, checked, disabled, onChange, className, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(ev) => {
            onChange(ev.target.checked)
          }}
          color="primary"
          disabled={disabled}
          className={className}
        />
      }
      style={{ userSelect: 'none' }}
      label={label}
    />
  )
}

RmCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default RmCheckbox
