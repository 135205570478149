import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import Button from '@mui/material/Button/index.js'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import EditableEmail from './EditableEmail.jsx'
import AppBuildConfig from '../../../shared-universal/AppBuildConfig.js'
import RmTheme from '../../../shared-universal/RmTheme.js'
import RmTooltip from './RmTooltip.jsx'
import RmRadioButtons from './RmRadioButtons.jsx'
import { isValidEmail } from '../../../shared-universal/Utils.js'

const dfVisitAllNodes = (node, visitNodeFunc) => {
  visitNodeFunc(node)
  ;(node.items || []).forEach((child) => dfVisitAllNodes(child, visitNodeFunc))
}

const buildOverrideSetStringForReport = (annualReport) => {
  let buildOverrideStr = ''

  const collectPrevLeafXbrlname = (node, outputArray) => {
    const isExprInForegaendeAr = node.isExpr && node.emitIf !== 'currentYear'
    if (!node.items && !isExprInForegaendeAr) {
      outputArray.push(node.xbrlName)
    }
  }
  // Build array of all xbrl names that are prev year manual inputs (non-exprs) in income statement and balance sheet
  const incomeStatementPrevYearInputs = []
  dfVisitAllNodes(annualReport.clientIncomeStatementTemplate, (node) =>
    collectPrevLeafXbrlname(node, incomeStatementPrevYearInputs)
  )
  const balanceSheetPrevYearInputs = []
  dfVisitAllNodes(annualReport.clientBalanceSheetTemplate, (node) =>
    collectPrevLeafXbrlname(node, balanceSheetPrevYearInputs)
  )

  const maybeAddXbrlValueToOverrideStr = (xbrlName, fYearIdx) => {
    const value = annualReport.financialYears[fYearIdx].xbrlValues[xbrlName]?.value
    if (value !== undefined && value !== null) {
      buildOverrideStr += `${xbrlName}${fYearIdx == 1 ? ':ForegaendeAr' : ''}=${
        annualReport.financialYears[1].xbrlValues[xbrlName]?.value
      },\\\n`
    }
  }

  if (annualReport.financialYears.length > 1) {
    incomeStatementPrevYearInputs.forEach((xbrlName) => maybeAddXbrlValueToOverrideStr(xbrlName, 1))
    buildOverrideStr += '\\\n'
    balanceSheetPrevYearInputs.forEach((xbrlName) => maybeAddXbrlValueToOverrideStr(xbrlName, 1))
    buildOverrideStr += '\\\n'
  }

  Object.keys(annualReport.financialYears[0].xbrlOverrides).forEach((xbrlName) => {
    const value = annualReport.financialYears[0].xbrlOverrides[xbrlName]?.value
    if (value !== undefined && value !== null) {
      buildOverrideStr += `${xbrlName}=${value},\\\n`
    }
  })
  if (annualReport.financialYears.length > 1) {
    Object.keys(annualReport.financialYears[1].xbrlOverrides).forEach((xbrlName) => {
      if (!incomeStatementPrevYearInputs.includes(xbrlName) && !balanceSheetPrevYearInputs.includes(xbrlName)) {
        const value = annualReport.financialYears[1].xbrlOverrides[xbrlName]?.value
        if (value !== undefined && value !== null) {
          buildOverrideStr += `${xbrlName}:ForegaendeAr=${value},\\\n`
        }
      }
    })
  }

  return buildOverrideStr
}

const KontoinformationEditor = ({ annualReport, onChange }) => {
  const abortSignal = useAbortSignal()
  const [confirmationEmailSent, setConfirmationEmailSent] = React.useState(false)
  const [sendEmailButtonDisabled, setSendEmailButtonDisabled] = React.useState(false)
  return (
    <React.Fragment>
      <h3>Kontoinformation</h3>
      <div>E-mail</div>
      <Box mt={1} />
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ flexBasis: '550px' }}>
          <EditableEmail
            className="automation-account-email"
            defaultValue={annualReport.email}
            onChange={(newEmail) => {
              API.saveAnnualReportValue(annualReport.reportId, 'email', newEmail, abortSignal, onChange)
            }}
          />
        </div>
        {annualReport.email === annualReport.confirmedEmail && (
          <div style={{ position: 'relative', top: '7px', marginLeft: '10px', color: RmTheme.PRIMARY_COLOR }}>
            <RmTooltip title={`Din e-post ${annualReport.email} är bekräftad.`}>
              <CheckCircleOutlinedIcon />
            </RmTooltip>
          </div>
        )}
        {annualReport.email !== annualReport.confirmedEmail && (
          <div style={{ position: 'relative', top: '7px', marginLeft: '10px' }}>
            <RmTooltip title={`Din e-post ${annualReport.email} är ännu inte bekräftad.`}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                <div style={{ position: 'relative', top: '2px', color: RmTheme.ERROR_COLOR }}>
                  <ReportProblemOutlinedIcon />
                </div>
                <div>Din e-post är ej bekräftad.</div>
              </div>
            </RmTooltip>
          </div>
        )}
      </div>
      {annualReport.email !== annualReport.confirmedEmail && (
        <>
          <p>Du behöver en bekräftad e-post för att kunna logga in i appen vid senare tillfälle.</p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={{ flexBasis: '350px' }}>
              <RmTooltip
                visible={!isValidEmail(annualReport.email)}
                title={'En giltig e-post adress måste anges innan vi kan skicka ett e-post men en bekräftelselänk.'}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfirmationEmailSent(true)
                    setSendEmailButtonDisabled(true)
                    API.startEmailConfirmationFlow(abortSignal)
                    setTimeout(() => {
                      setSendEmailButtonDisabled(false)
                    }, 6000)
                  }}
                  disabled={!isValidEmail(annualReport.email) || sendEmailButtonDisabled}
                >
                  Skicka ny bekräftelselänk
                </Button>
              </RmTooltip>
            </div>
            {confirmationEmailSent && (
              <div>
                Vi har skickat ett bekräftelse e-post till dig på {annualReport.email}, klicka på länken i mailet för
                att bekräfta din e-post.
              </div>
            )}
          </div>
        </>
      )}

      <Box mt={5} />
      <h4>Hantering av e-mail</h4>
      <RmRadioButtons
        value={annualReport.emailPref || 'normal'}
        items={[
          { value: 'minimal', label: 'Skicka endast nödvändiga e-mail (tex inloggningslänkar)' },
          {
            value: 'normal',
            label: 'Skicka e-mail med information om stora ändringar i tjänsten, erbjudanden osv (ca 0-2 mail per år)',
          },
        ]}
        onChange={(ev) => {
          API.saveAnnualReportValue(annualReport.reportId, 'emailPref', ev.target.value, abortSignal, onChange, {
            immediate: true,
          })
        }}
      />

      <Box mt={4} />
      {localStorage && localStorage.getItem(AppBuildConfig.DEBUG_LOCALSTORAGE_KEY) === 'true' && (
        <React.Fragment>
          <Button onClick={() => navigator.clipboard.writeText(buildOverrideSetStringForReport(annualReport))}>
            Copy manual input overrides
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

KontoinformationEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
}

export default KontoinformationEditor
