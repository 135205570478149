const NBSP_CHARACTER = '\xa0'
const svSE = new Intl.NumberFormat('sv-SE')
import { notBalancePostVisible, getBalanceSheetPostsWithNotes } from './BalanceNoteSpecs.js'

const formatNumber = (num, opts) => {
  let formattedNumber = svSE.format(num)
  if (!opts.nbspThousandSeparator) {
    formattedNumber = formattedNumber.replaceAll(NBSP_CHARACTER, ' ')
  }

  return formattedNumber
}

const formatCell = (cell, opts) => {
  if (cell && cell.value !== undefined && cell.value !== null && !Number.isNaN(cell.value)) {
    let presentationValue = cell.value / Math.pow(10, opts.scale || 0)
    if (opts.scale > 0) {
      presentationValue = Number(presentationValue).toFixed(0)
    }
    if (opts.presentationToggleSign) {
      presentationValue *= -1
    }
    if (opts.decimalCount) {
      return `${formatNumber(presentationValue, opts)} (${cell.rawAmount.toFixed(opts.decimalCount)})`
    } else {
      return formatNumber(presentationValue, opts)
    }
  } else {
    return '0'
  }
}

const REVBER_MODIFIED_OPTIONS = [
  {
    id: 'RevisorspateckningMeningAvstyrktAttResultatrakningBalansrakningFaststallsMember',
    title: 'Jag har i denna avstyrkt att resultaträkningen och balansräkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningAvstyrktAttResultatrakningFaststallsMember',
    title: 'Jag har i denna avstyrkt att resultaträkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningAvstyrktAttBalansrakningFaststallsMember',
    title: 'Jag har i denna avstyrkt att balansräkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningAvstyrktAttResultatrakningVarkenTillEllerAvstyrktBalansrakningFaststallsMember',
    title:
      'Jag har i denna avstyrkt att resultaträkningen och varken till- eller avstyrkt att balansräkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningAvstyrktAttBalanstrakningVarkenTillEllerAvstyrktResultatrakningFaststallsMember',
    title:
      'Jag har i denna avstyrkt att balansräkningen och varken till- eller avstyrkt att resultaträkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningBalansrakningFaststallsMember',
    title: 'Jag har i denna varken till- eller avstyrkt att resultaträkningen och balansräkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningFaststallsMember',
    title: 'Jag har i denna varken till- eller avstyrkt att resultaträkningen fastställs',
  },
  {
    id: 'RevisorspateckningMeningVarkenTillEllerAvstyrktAttBalansrakningFaststallsMember',
    title: 'Jag har i denna varken till- eller avstyrkt att balansräkningen fastställs',
  },
]

const changesToEquityOptionalColumns = [
  {
    xbrlName: 'Aktiekapital',
    title: 'Aktiekapital',
    beraknatExpr: `Aktiekapital:ForegaendeAr +
      ForandringEgetKapitalAktiekapitalFondemission +
      ForandringEgetKapitalAktiekapitalNyemission +
      ForandringEgetKapitalAktiekapitalOverforingFrittEgetKapitalAterbetalningAktieagare +
      ForandringEgetKapitalAktiekapitalTackningForlust`,
  },
  {
    xbrlName: 'EjRegistreratAktiekapital',
    title: 'Ej registrerat aktiekapital',
    beraknatExpr: `EjRegistreratAktiekapital:ForegaendeAr +
      ForandringEgetKapitalEjRegistreratAktiekapitalFondemission +
      ForandringEgetKapitalEjRegistreratAktiekapitalNyemission`,
  },
  {
    xbrlName: 'OverkursfondBunden',
    title: 'Bunden överkursfond',
    beraknatExpr: `OverkursfondBunden:ForegaendeAr -
      ForandringEgetKapitalBundenOverkursfondFondemission +
      ForandringEgetKapitalBundenOverkursfondNyemission +
      ForandringEgetKapitalBundenOverkursfondMinskningBundenOverskursfond +
      ForandringEgetKapitalBundenOverkursfondTackningForlust`,
  },
  {
    xbrlName: 'Uppskrivningsfond',
    title: 'Uppskrivnings\u00ADfond',
    beraknatExpr: `Uppskrivningsfond:ForegaendeAr -
      ForandringEgetKapitaUppskrivningsfondFondemission +
      ForandringEgetKapitalUppskrivningsfondTackningForlust +
      ForandringEgetKapitalUppskrivningsfondUppskrivningAnlaggningstillgang +
      ForandringEgetKapitalUppskrivningsfondUpplosningUppskrivningsfond +
      ForandringEgetKapitalUppskrivningsfondFusionsdifferens`,
  },
  {
    xbrlName: 'Reservfond',
    title: 'Reservfond',
    beraknatExpr: `Reservfond:ForegaendeAr +
      ForandringEgetKapitalReservfondForandringReservfond -
      ForandringEgetKapitalReservfondFondemission +
      ForandringEgetKapitalReservfondTackningForlust`,
  },
  {
    xbrlName: 'Overkursfond',
    title: 'Fri överkursfond',
    beraknatExpr: `Overkursfond:ForegaendeAr +
      ForandringEgetKapitalOverkursfondUtdelning +
      ForandringEgetKapitalOverkursfondAterbetalningAktieagartillskott +
      ForandringEgetKapitalOverkursfondBalanserasNyRakning +
      ForandringEgetKapitalOverkursfondForandringReservfond -
      ForandringEgetKapitalOverkursfondFondemission +
      ForandringEgetKapitalOverkursfondNyemission +
      ForandringEgetKapitalOverkursfondTackningForlust`,
  },
]

const getPeriodtyp = (xbrlName) => {
  if (xbrlName === 'LanAktieagareFysiskPersonPeriodensUtgang') {
    return 'instant'
  }
  return 'duration'
}

const getXbrlNamespace = (xbrlName) => {
  const taxBaseXbrlNames = [
    'OkningAvSkattemassigtResultatMedAnledningAvBeraknadSchablonintaktPaPeriodiseringsfonderVidBeskattningsaretsIngang',
    'OkningAvSkattemassigtResultatMedAnledningAvBeraknadSchablonintaktPaFondandelarAgdaVidKalenderaretsIngang',
    'OkningAvSkattemassigtResultatMedAnledningAvUppraknatBeloppVidAterforingAvPeriodiseringsfond',
    'MinskningAvSkattemassigtResultatMedAnledningAvUppskovMedKapitalvinst',
    'OkningAvSkattemassigtResultatMedAnledningAvAterfortUppskovAvKapitalvinst',
    'OkningAvSkattemassigtResultatMedAnledningAvKapitalvinstVidAvyttringAvDelagarratterSomArSkattepliktig',
    'MinskningAvSkattemassigtResultatMedAnledningAvKapitalforlustVidAvyttringAvDelagarratterSomArSkattemassigtAvdragsgill',
    'OkningAvSkattemassigtResultatMedAnledningAvSkattemassigtOverskottFranAndelIHandelsbolag',
    'MinskningAvSkattemassigtResultatMedAnledningAvSkattemassigtUnderskottFranAndelIHandelsbolag',
    'OkningMinskningAvSkattemassigtResultatMedAnledningAvSkattemassigJusteringAvBokfortResultatForAvskrivningPaByggnaderOchAnnanFastEgendomSamtVidRestvardesavskrivningPaMaskinerOchInventarier',
    'OkningMinskningAvSkattemassigtResultatMedAnledningAvSkattemassigJusteringAvBokfortResultatVidAvyttringAvNaringsfastighetOchNaringsbostadsratt',
    'MinskningAvSkattemassigtResultatMedAnledningAvSkogsSubstansminskningsavdrag',
    'OkningAvSkattemassigtResultatMedAnledningAvAterforingarVidAvyttringAvFastighetTExVardeminskningsavdragSkogsavdragOchSubstansminskningsavdrag',
    'OkningMinskningAvSkattemassigtResultatMedAnledningAvAndraSkattemassigaJusteringarAvResultatet',
    'MinskningAvSkattemassigtResultatMedAnledningAvOutnyttjatUnderskottFranForegaendeAr',
    'OkningAvSkattemassigtResultatMedAnledningAvReduktionAvOutnyttjatUnderskottMedHansynTillBeloppssparrAckordEllerKonkursMM',
    'OkningAvSkattemassigtResultatMedAnledningAvReduktionAvOutnyttjatUnderskottMedHansynTillKoncernbidragssparrFusionssparrMM',
    'OverskottEllerUnderskott',
  ]
  if (xbrlName.startsWith('ArsstammaResultatDisposition')) {
    return 'se-bol-base'
  }
  if (taxBaseXbrlNames.includes(xbrlName)) {
    return 'se-tax-base'
  }
  return 'se-gen-base'
}

const formatIXbrl = (xbrlValues, contextRef, xbrlName, opts) => {
  const scale = opts.scale ?? 0
  const xbrlNamespace = getXbrlNamespace(xbrlName)
  const cell = xbrlValues[xbrlName]
  if (opts.disableXbrlTagging) {
    return formatCell(cell, opts)
  }
  if (!contextRef.startsWith('period') && !contextRef.startsWith('balans')) {
    throw Error(`invalid contextRef "${contextRef}" when emitting xbrl tag for "${xbrlName}"`)
  }
  let maybeSignAttribute = ''
  let maybeMinusSign = ''
  let resolvedCell
  if (cell && cell.value !== undefined && cell.value !== null) {
    resolvedCell = { value: Math.abs(cell.value), rawAmount: cell.rawAmount }
    if (cell.value < 0) {
      maybeSignAttribute = ' sign="-"'
    }
    let presentationValue = Number((cell.value / Math.pow(10, opts.scale || 0)).toFixed(0))
    if (opts.presentationToggleSign) {
      presentationValue *= -1
    }
    if (presentationValue < 0) {
      maybeMinusSign = '−'
    }
  } else {
    resolvedCell = cell
  }
  return `${maybeMinusSign}<ix:nonFraction name="${xbrlNamespace}:${xbrlName}" contextRef="${contextRef}" unitRef="${
    opts.unitRef || 'SEK'
  }" decimals="${
    scale > 0 ? -1 * scale : 'INF'
  }" scale="${scale}" format="ixt:numspacecomma"${maybeSignAttribute}>${formatCell(resolvedCell, {
    ...opts,
    presentationToggleSign: false,
  })}</ix:nonFraction>`
}

const getInitialAktiekapitalForFirstYear = (firstYearXbrlValues) => {
  const initialAktiekapital = {
    value:
      (firstYearXbrlValues['Aktiekapital']?.value || 0) -
      (firstYearXbrlValues['ForandringEgetKapitalAktiekapitalNyemission']?.value || 0),
    rawAmount:
      (firstYearXbrlValues['Aktiekapital']?.rawAmount || 0) -
      (firstYearXbrlValues['ForandringEgetKapitalAktiekapitalNyemission']?.rawAmount || 0),
  }
  return initialAktiekapital
}

const getOpeningBalancesForChangesToEquityTable = (annualReport) => {
  let isFirstYear
  let openingBalanceXbrlValues
  if (annualReport.financialYears.length > 1) {
    isFirstYear = false
    openingBalanceXbrlValues = annualReport.financialYears[1].xbrlValues
  } else {
    isFirstYear = true
    const initialAktiekapital = getInitialAktiekapitalForFirstYear(annualReport.financialYears[0].xbrlValues)
    openingBalanceXbrlValues = {
      Aktiekapital: initialAktiekapital,
      EjRegistreratAktiekapital: { value: 0, rawAmount: 0 },
      OverkursfondBunden: { value: 0, rawAmount: 0 },
      Uppskrivningsfond: { value: 0, rawAmount: 0 },
      Reservfond: { value: 0, rawAmount: 0 },
      Overkursfond: { value: 0, rawAmount: 0 },
      BalanseratResultat: { value: 0, rawAmount: 0 },
      AretsResultat: { value: 0, rawAmount: 0 },
      ForandringEgetKapitalTotalt: initialAktiekapital,
    }
  }
  return { isFirstYear, openingBalanceXbrlValues }
}

const APPROVABLE_SECTIONS = [
  { sectionId: 'bokforing', title: 'Importera bokföring', title2: 'bokföringen' },
  { sectionId: 'foretagsinformation', title: 'Företagsinformation', title2: 'företagsinformationen' },
  { sectionId: 'resultatrakning', title: 'Resultaträkning', title2: 'resultaträkningen' },
  { sectionId: 'balansrakning', title: 'Balansräkning', title2: 'balansräkningen' },
  { sectionId: 'skatteberakning', title: 'Skatteberäkning', title2: 'skatteberäkningen' },
  { sectionId: 'forvaltningsberattelse', title: 'Förvaltningsberättelse', title2: 'förvaltningsberättelsen' },
  { sectionId: 'noter', title: 'Noter', title2: 'noterna' },
  { sectionId: 'fardigstall', title: 'Färdigställ', title2: 'årsredovisningen' },
]

const getApprovableSections = (annualReport) => {
  return APPROVABLE_SECTIONS
}

const triggerCascadingUnapprove = (annualReport, sectionApprovals) => {
  const resolvedSectionApprovals = getApprovableSections(annualReport).map(
    (section) =>
      sectionApprovals.find((s) => s.sectionId === section.sectionId) ?? {
        sectionId: section.sectionId,
        isApproved: false,
      }
  )
  let allApprovedSoFar = true
  resolvedSectionApprovals.forEach((sectionApprovalEntry) => {
    if (!sectionApprovalEntry.isApproved) {
      allApprovedSoFar = false
    }
    if (!allApprovedSoFar) {
      sectionApprovalEntry.isApproved = false
    }
  })
  return resolvedSectionApprovals
}

const notLangfristigaSkulderVisible = (financialYears) => {
  return Boolean(financialYears[0].xbrlValues['LangfristigaSkulder']?.value)
}

const shouldNoteBeVisible = (noteId, annualReport, noteRefInHeader) => {
  const bsPostsWithNotes = getBalanceSheetPostsWithNotes()
  if (noteId === 'Redovisningsprinciper') {
    return true
  } else if (noteId === 'NotMedelantaletAnstallda') {
    // Citat: "Vissa noter ska alla företag ha med, så som vilka principer för värdering som företaget använder sig av och medelantalet anställda under räkenskapsåret."
    // https://bolagsverket.se/ff/foretagsformer/aktiebolag/arsredovisning/delar/upplysningar-1.3131
    if (annualReport.financialYears[0].xbrlValues['Personalkostnader']?.value) {
      return !noteRefInHeader
    } else {
      return noteRefInHeader
    }
  } else if (noteId === 'NotLangfristigaSkulder') {
    return Boolean(annualReport.financialYears[0].xbrlValues['LangfristigaSkulder']?.value)
  } else if (bsPostsWithNotes.includes(noteId.slice(3))) {
    return notBalancePostVisible(annualReport, noteId.slice(3))
  } else {
    throw Error(`Unknown noteId: ${noteId}`)
  }
}

const taxLanFranAktieagareVisible = (financialYears) => {
  return (
    Boolean(financialYears[0].xbrlValues['LangfristigaSkulder']?.value) ||
    Boolean(financialYears[0].xbrlValues['KortfristigaSkulder']?.value)
  )
}

const getIsApproved = (annualReport, sectionId) =>
  annualReport?.sectionApprovals?.find((appr) => appr.sectionId === sectionId)?.isApproved ?? false
const getIsAllApproved = (annualReport) => {
  return getApprovableSections(annualReport).every((section) => getIsApproved(annualReport, section.sectionId))
}

const hasValue = (cell) => {
  const maybeValue = cell?.value
  return maybeValue !== undefined && maybeValue !== null
}

const hasNonZeroRawAmount = (cell) => {
  const maybeRawAmount = cell?.rawAmount
  return maybeRawAmount !== undefined && maybeRawAmount !== null && Number(maybeRawAmount.toFixed(2)) !== 0
}

const extraCss = `
  .rm_page:not(:first-child) .rm_margin-bottom {
    border-top: 1px solid black;
  }

  .text-align-center {
    text-align: center;
  }

  .note-indented-cell {
    padding-left: 1em;
  }

  .certificate-of-approval {
    border: 1px solid black;
    width: 70%;
    margin: auto;
    padding: 10px;
  }

  .certificate-of-approval-heading {
    margin-top: 0px;
  }

  .dotted-signature-line {
    height: 75px;
    width: 300px;
    border-bottom: 1px dotted black;
  }

  .resultatdisposition-part-1-label {
    margin-bottom: 4px;
  }
  .resultatdisposition-part-2-label {
    margin-top: 30px;
    margin-bottom: 4px;
  }
  .resultatdisposition-yttrande-heading {
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .note-sum-cell {
    font-weight: bold;
    padding-bottom: 1em;
  }

  .note-sum-value-cell {
    border-top: 1px solid black;
  }

  .narrow-table {
    width: 70%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .summary-table {
    width: 100%;
    border-collapse: collapse;
  }

  .summary-table tr.thead td {
    border-bottom: 1px solid black;
  }

  .summary-table tr.thead td:not(:first-child) {
    font-weight: bold;
    text-align: right;
    width: 105px;
  }

  .summary-table.narrow-table tr.thead td:not(:first-child) {
    width: 82px;
  }

  .numerical-amount {
    vertical-align: bottom;
    text-align: right;
    width: 105px;
  }

  .numerical-amount-column-caption {
    vertical-align: top;
    text-align: right;
  }

  .section-sum-row {
    padding-bottom: 1em;
  }

  .stage-summary-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .stage-summary-row {
    border-top: 1px solid black;
    padding-bottom: 1em;
  }

  .level-0-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .level-1-title {
    font-weight: bold;
  }

  .level-2-title {
    font-weight: bold;
    font-style: italic;
  }

  .note-content-box {
    margin-bottom: 32px;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .user-select-none {
    user-select: none;
  }

  .align-bottom {
    vertical-align: bottom;
  }

  .whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .break-inside-avoid {
    break-inside: avoid;
  }

  .break-inside-avoid-table tr, .break-inside-avoid td {
    break-inside: avoid;
  }
`

const getReportRootCss = () => {
  return `
    .report-root {
      font-size: 13px;
      color: #000;
    }
    .report-root a {
      color: #000;
      text-decoration: none;
    }
    .report-root table {
      font-size: 13px;
    }
    @media screen {
      .report-root a, .report-root {
        color: #404040;
      }
    }
  `
}

const getCompanyVatNr = (annualReport) => `SE${annualReport.organizationNumber.replace('-', '')}01`

const getBuyerVatNr = (annualReport) =>
  annualReport.buyerOrgnr ? `SE${annualReport.buyerOrgnr.replace('-', '')}01` : getCompanyVatNr(annualReport)

const getAnnualReportIssuer = (annualReport) => {
  const coaSigner = annualReport.signatures.find((sig) => sig.foretradareId === annualReport.coaSigner)
  const issuer = (coaSigner?.role || '') === 'li' ? `Likvidatorn` : 'Styrelsen'
  return issuer
}

export {
  formatNumber,
  formatCell,
  formatIXbrl,
  REVBER_MODIFIED_OPTIONS,
  changesToEquityOptionalColumns,
  getInitialAktiekapitalForFirstYear,
  getOpeningBalancesForChangesToEquityTable,
  getIsApproved,
  getIsAllApproved,
  getApprovableSections,
  triggerCascadingUnapprove,
  notLangfristigaSkulderVisible,
  taxLanFranAktieagareVisible,
  shouldNoteBeVisible,
  hasValue,
  hasNonZeroRawAmount,
  extraCss,
  getReportRootCss,
  getXbrlNamespace,
  getPeriodtyp,
  getCompanyVatNr,
  getBuyerVatNr,
  getAnnualReportIssuer,
}
