import React from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

const BolagsverketActionMenu = ({ bolagsverketSignLink }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const closeMenu = () => setAnchorEl(null)

  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget)
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={closeMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={async () => {
            closeMenu()
            navigator.clipboard.writeText(bolagsverketSignLink)
          }}
        >
          <ListItemIcon>
            <ContentCopyOutlinedIcon />
          </ListItemIcon>
          Kopiera signeringslänk
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

BolagsverketActionMenu.propTypes = {
  bolagsverketSignLink: PropTypes.string.isRequired,
}

BolagsverketActionMenu.defaultProps = {}

export default BolagsverketActionMenu
