import {
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden,
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar,
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar,
  arHyresratterLiknandeRattigheterAnskaffningsvarden,
  arHyresratterLiknandeRattigheterAvskrivningar,
  arHyresratterLiknandeRattigheterNedskrivningar,
  arGoodwillAnskaffningsvarden,
  arGoodwillAvskrivningar,
  arGoodwillNedskrivningar,
  arByggnaderMarkAnskaffningsvarden,
  arByggnaderMarkNedskrivningar,
  arByggnaderMarkAvskrivningar,
  arMaskinerAndraTekniskaAnlaggningarAnskaffningsvarden,
  arMaskinerAndraTekniskaAnlaggningarNedskrivningar,
  arMaskinerAndraTekniskaAnlaggningarAvskrivningar,
  arInventarierVerktygInstallationerAnskaffningsvarden,
  arInventarierVerktygInstallationerNedskrivningar,
  arInventarierVerktygInstallationerAvskrivningar,
  arOvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden,
  arOvrigaMateriellaAnlaggningstillgangarNedskrivningar,
  arOvrigaMateriellaAnlaggningstillgangarAvskrivningar,
  arPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden,
  arAndelarKoncernforetagAnskaffningsvarden,
  arAndelarKoncernforetagNedskrivningar,
  arFordringarKoncernforetagLangfristigaAnskaffningsvarden,
  arFordringarKoncernforetagLangfristigaNedskrivningar,
  arAndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden,
  arAndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar,
  arAgarintressenOvrigaForetagAnskaffningsvarden,
  arAndraLangfristigaVardepappersinnehavAnskaffningsvarden,
  arAndraLangfristigaVardepappersinnehavNedskrivningar,
  arAndraLangfristigaFordringarAnskaffningsvarden,
  arAndraLangfristigaFordringarNedskrivningar,
} from './AccountRanges.js'
import { findTableNode } from './RemakerUtils.js'
import { addEssentialTypos } from './AnnualReportRender.js'

/*
 * When adding new balance notes and/or changes here, refer to the spreadsheet
 * k2-arsredovisning-2021-10-31-rev20211210 (or whatever is the latest version)
 *
 * change.xbrlWeight should be specified according to the "Standardrubrik" column:
 *
 * Standardrubrik                           change.xbrlWeight
 * Ökning ...                               1
 * Ökning (minskning) ...                   1
 * Minskning ...                            -1
 * Minskning (ökning)...                    -1
 * ... others are judged case-by-case       1 or -1
 *
 * change.showAdornment is set to true for all changes except those that start
 * with "Ökning (minskning) ..." or "Minskning (ökning)..."
 */

const changeSpecs = [
  {
    changeId: 'Inkop',
    xbrlSuffix: 'Inkop',
    title: 'Inköp',
    showAdornment: true,
  },
  {
    changeId: 'Forsaljningar',
    xbrlSuffix: 'Forsaljningar',
    title: 'Försäljningar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'ForsaljningarUtrangeringar',
    xbrlSuffix: 'ForsaljningarUtrangeringar',
    title: 'Försäljningar/utrangeringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AterfordaNedskrivningarForsaljningar',
    xbrlSuffix: 'AterfordaNedskrivningarForsaljningar',
    title: 'Försäljningar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AterfordaUppskrivningarForsaljningarRespUtrangeringar',
    xbrlSuffix: 'AterfordaUppskrivningarForsaljningarRespUtrangeringar',
    title: 'Försäljningar/utrangeringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
    xbrlSuffix: 'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
    title: 'Försäljningar/utrangeringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
    xbrlSuffix: 'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
    title: 'Försäljningar/utrangeringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'Fusion',
    xbrlSuffix: 'Fusion',
    title: 'Fusion',
  },
  {
    changeId: 'AterfordaAvskrivningarFusion',
    xbrlSuffix: 'AterfordaAvskrivningarFusion',
    title: 'Fusion',
  },
  {
    changeId: 'AretsAvskrivningar',
    xbrlSuffix: 'AretsAvskrivningar',
    title: 'Årets avskrivningar',
    showAdornment: true,
  },
  {
    changeId: 'AretsUppskrivningar',
    xbrlSuffix: 'AretsUppskrivningar',
    title: 'Årets uppskrivningar',
    showAdornment: true,
  },
  {
    changeId: 'AretsAvskrivningarUppskrivetBelopp',
    xbrlSuffix: 'AretsAvskrivningarUppskrivetBelopp',
    title: 'Årets avskrivningar på uppskrivet belopp',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AretsNedskrivningar',
    xbrlSuffix: 'AretsNedskrivningar',
    title: 'Årets nedskrivningar',
    showAdornment: true,
  },
  {
    changeId: 'Rorelseforvarv',
    xbrlSuffix: 'Rorelseforvarv',
    title: 'Rörelseförvärv',
  },
  {
    changeId: 'OmklassificeringarPositiv',
    xbrlSuffix: 'Omklassificeringar',
    title: 'Omklassificeringar',
  },
  {
    changeId: 'OmklassificeringarNegativ',
    xbrlSuffix: 'Omklassificeringar',
    title: 'Omklassificeringar',
    xbrlWeight: -1,
  },
  {
    changeId: 'AretsOmklassificeringarPositiv',
    xbrlSuffix: 'AretsOmklassificeringar',
    title: 'Omklassificeringar',
  },
  {
    changeId: 'AretsOmklassificeringarNegativ',
    xbrlSuffix: 'AretsOmklassificeringar',
    title: 'Omklassificeringar',
    xbrlWeight: -1,
  },
  {
    changeId: 'OmrakningsdifferenserPositiv',
    xbrlSuffix: 'Omrakningsdifferenser',
    title: 'Omräkningsdifferenser',
  },
  {
    changeId: 'OmrakningsdifferenserNegativ',
    xbrlSuffix: 'Omrakningsdifferenser',
    title: 'Omräkningsdifferenser',
    xbrlWeight: -1,
  },
  {
    changeId: 'AretsOmrakningsdifferenserPositiv',
    xbrlSuffix: 'AretsOmrakningsdifferenser',
    title: 'Omräkningsdifferenser',
  },
  {
    changeId: 'AretsOmrakningsdifferenserNegativ',
    xbrlSuffix: 'AretsOmrakningsdifferenser',
    title: 'Omräkningsdifferenser',
    xbrlWeight: -1,
  },
  {
    changeId: 'AterfordaNedskrivningar',
    xbrlSuffix: 'AterfordaNedskrivningar',
    title: 'Återförda nedskrivningar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'AretsResultatAndel',
    xbrlSuffix: 'AretsResultatAndel',
    title: 'Årets resultatandel',
  },
  {
    changeId: 'NedlagdaUtgifter',
    xbrlSuffix: 'NedlagdaUtgifter',
    title: 'Nedlagda utgifter',
    showAdornment: true,
  },
  {
    changeId: 'UtbetaladeForskott',
    xbrlSuffix: 'UtbetaladeForskott',
    title: 'Utbetalda förskott',
    showAdornment: true,
  },
  {
    changeId: 'AterbetaladeForskott',
    xbrlSuffix: 'AterbetaladeForskott',
    title: 'Återbetalda förskott',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'BortskrivnaForskott',
    xbrlSuffix: 'BortskrivnaForskott',
    title: 'Bortskrivna förskott',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'LamnadeAktieagartillskott',
    xbrlSuffix: 'LamnadeAktieagartillskott',
    title: 'Lämnade aktieägartillskott',
    showAdornment: true,
  },
  {
    changeId: 'AterbetalningLamnatAktieagartillskott',
    xbrlSuffix: 'AterbetalningLamnatAktieagartillskott',
    title: 'Återbetalning av lämnat aktieägartillskott',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'TillkommandeFordringar',
    xbrlSuffix: 'TillkommandeFordringar',
    title: 'Tillkommande fordringar',
    showAdornment: true,
  },
  {
    changeId: 'RegleradeFordringar',
    xbrlSuffix: 'RegleradeFordringar',
    title: 'Reglerade fordringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
  {
    changeId: 'Bortskrivnafordringar',
    xbrlSuffix: 'Bortskrivnafordringar',
    title: 'Bortskrivna fordringar',
    showAdornment: true,
    xbrlWeight: -1,
  },
]

const balanceNoteSpecs = [
  {
    balanceXbrlName: 'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'HyresratterLiknandeRattigheter',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arHyresratterLiknandeRattigheterAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arHyresratterLiknandeRattigheterAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'AterfordaAvskrivningarFusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arHyresratterLiknandeRattigheterNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'Goodwill',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arGoodwillAnskaffningsvarden,
        changeIds: ['Rorelseforvarv', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arGoodwillAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'AterfordaAvskrivningarFusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arGoodwillNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'ByggnaderMark',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arByggnaderMarkAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arByggnaderMarkAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Uppskrivningar',
        title: 'Uppskrivningar',
        changeIds: ['AretsUppskrivningar', 'AretsAvskrivningarUppskrivetBelopp'],
        allChangeIds: [
          'Fusion',
          'AterfordaUppskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarPositiv',
          'AretsUppskrivningar',
          'AretsAvskrivningarUppskrivetBelopp',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arByggnaderMarkNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'MaskinerAndraTekniskaAnlaggningar',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arMaskinerAndraTekniskaAnlaggningarAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arMaskinerAndraTekniskaAnlaggningarAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arMaskinerAndraTekniskaAnlaggningarNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'InventarierVerktygInstallationer',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arInventarierVerktygInstallationerAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arInventarierVerktygInstallationerAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arInventarierVerktygInstallationerNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'OvrigaMateriellaAnlaggningstillgangar',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arOvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden,
        changeIds: ['Inkop', 'ForsaljningarUtrangeringar'],
        allChangeIds: [
          'Inkop',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Avskrivningar',
        title: 'Avskrivningar',
        accountRanges: arOvrigaMateriellaAnlaggningstillgangarAvskrivningar,
        xbrlWeight: -1,
        changeIds: ['AterfordaAvskrivningarForsaljningarRespUtrangeringar', 'AretsAvskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaAvskrivningarForsaljningarRespUtrangeringar',
          'OmklassificeringarNegativ',
          'AretsAvskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arOvrigaMateriellaAnlaggningstillgangarNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden,
        changeIds: ['NedlagdaUtgifter'],
        allChangeIds: [
          'NedlagdaUtgifter',
          'UtbetaladeForskott',
          'Rorelseforvarv',
          'Fusion',
          'ForsaljningarUtrangeringar',
          'AterbetaladeForskott',
          'BortskrivnaForskott',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningarRespUtrangeringar',
          'AterfordaNedskrivningar',
          'BortskrivnaForskott',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'AndelarKoncernforetag',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arAndelarKoncernforetagAnskaffningsvarden,
        changeIds: ['AretsResultatAndel'],
        allChangeIds: [
          'Inkop',
          'Fusion',
          'Forsaljningar',
          'LamnadeAktieagartillskott',
          'AterbetalningLamnatAktieagartillskott',
          'AretsResultatAndel',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        xbrlWeight: -1,
        accountRanges: arAndelarKoncernforetagNedskrivningar,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningar',
          'AterfordaNedskrivningar',
          'AretsOmklassificeringarNegativ',
          'AretsNedskrivningar',
          'AretsOmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'FordringarKoncernforetagLangfristiga',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arFordringarKoncernforetagLangfristigaAnskaffningsvarden,
        changeIds: ['TillkommandeFordringar', 'RegleradeFordringar'],
        allChangeIds: [
          'TillkommandeFordringar',
          'Rorelseforvarv',
          'Fusion',
          'RegleradeFordringar',
          'Bortskrivnafordringar',
          'AretsOmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        xbrlWeight: -1,
        accountRanges: arFordringarKoncernforetagLangfristigaNedskrivningar,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'RegleradeFordringar',
          'Fusion',
          'AterfordaNedskrivningar',
          'Bortskrivnafordringar',
          'AretsOmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'AndelarIntresseforetagGemensamtStyrdaForetag',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arAndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden,
        changeIds: ['AretsResultatAndel'],
        allChangeIds: [
          'Inkop',
          'Fusion',
          'Forsaljningar',
          'LamnadeAktieagartillskott',
          'AterbetalningLamnatAktieagartillskott',
          'AretsResultatAndel',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        xbrlWeight: -1,
        accountRanges: arAndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },

  {
    balanceXbrlName: 'AgarintressenOvrigaForetag',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arAgarintressenOvrigaForetagAnskaffningsvarden,
        changeIds: ['Inkop', 'Forsaljningar'],
        allChangeIds: [
          'Inkop',
          'Fusion',
          'Forsaljningar',
          'LamnadeAktieagartillskott',
          'AterbetalningLamnatAktieagartillskott',
          'AretsResultatAndel',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: '',
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'AndraLangfristigaVardepappersinnehav',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arAndraLangfristigaVardepappersinnehavAnskaffningsvarden,
        changeIds: ['Inkop', 'Forsaljningar'],
        allChangeIds: [
          'Inkop',
          'Fusion',
          'Forsaljningar',
          'AretsResultatAndel',
          'AretsOmklassificeringarPositiv',
          'AretsOmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arAndraLangfristigaVardepappersinnehavNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'AterfordaNedskrivningarForsaljningar',
          'AterfordaNedskrivningar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
  {
    balanceXbrlName: 'AndraLangfristigaFordringar',
    parts: [
      {
        partId: 'Anskaffningsvarden',
        title: 'Anskaffningsvärden',
        accountRanges: arAndraLangfristigaFordringarAnskaffningsvarden,
        changeIds: ['TillkommandeFordringar', 'RegleradeFordringar'],
        allChangeIds: [
          'TillkommandeFordringar',
          'Fusion',
          'RegleradeFordringar',
          'Bortskrivnafordringar',
          'OmklassificeringarPositiv',
          'OmrakningsdifferenserPositiv',
        ],
      },
      {
        partId: 'Nedskrivningar',
        title: 'Nedskrivningar',
        accountRanges: arAndraLangfristigaFordringarNedskrivningar,
        xbrlWeight: -1,
        changeIds: ['AretsNedskrivningar'],
        allChangeIds: [
          'Fusion',
          'RegleradeFordringar',
          'AterfordaNedskrivningar',
          'Bortskrivnafordringar',
          'OmklassificeringarNegativ',
          'AretsNedskrivningar',
          'OmrakningsdifferenserNegativ',
        ],
      },
    ],
  },
]

const loadBalanceNoteSpec = (balanceXbrlName, balanceSheetTemplate) => {
  const balanceNoteSpec = balanceNoteSpecs.find((bn) => bn.balanceXbrlName === balanceXbrlName)
  balanceNoteSpec.title = findTableNode({ xbrlName: balanceXbrlName }, balanceSheetTemplate).title
  balanceNoteSpec.noteId = `Not${balanceXbrlName}`
  balanceNoteSpec.parts.forEach((part) => {
    part.xbrlName = `${balanceXbrlName}${part.partId}`
    part.changes = part.changeIds.map((changeId) =>
      JSON.parse(JSON.stringify(changeSpecs.find((changeSpec) => changeSpec.changeId === changeId)))
    )
    part.changes.forEach((change) => {
      change.xbrlName = addEssentialTypos(`${balanceXbrlName}Forandring${part.partId}${change.xbrlSuffix}`)
      if (!change.xbrlWeight) {
        change.xbrlWeight = 1
      }
    })
    part.allChanges = part.allChangeIds.map((changeId) => {
      const changeSpec = changeSpecs.find((changeSpec) => changeSpec.changeId === changeId)
      if (!changeSpec) {
        throw Error(`cannot find changeId: ${changeId}`)
      }
      return JSON.parse(JSON.stringify(changeSpec))
    })
    part.allChanges.forEach((change) => {
      change.xbrlName = addEssentialTypos(`${balanceXbrlName}Forandring${part.partId}${change.xbrlSuffix}`)
      if (!change.xbrlWeight) {
        change.xbrlWeight = 1
      }
    })
    if (!part.xbrlWeight) {
      part.xbrlWeight = 1
    }
  })
  return balanceNoteSpec
}

const notBalancePostVisible = (annualReport, balanceXbrl) => {
  const hasUtgaendeBalance = annualReport.financialYears[0].xbrlValues[balanceXbrl]?.value
  const hasIngaendeBalance =
    annualReport.financialYears.length > 1 && annualReport.financialYears[1].xbrlValues[balanceXbrl]?.value
  return Boolean(hasIngaendeBalance || hasUtgaendeBalance || annualReport.infogadeNoter[`Not${balanceXbrl}`])
}

const loadVisibleBalanceNoteSpecs = (annualReport, balanceSheetTemplate) => {
  return balanceNoteSpecs
    .filter((balanceNoteSpec) => notBalancePostVisible(annualReport, balanceNoteSpec.balanceXbrlName))
    .map((balanceNoteSpec) => loadBalanceNoteSpec(balanceNoteSpec.balanceXbrlName, balanceSheetTemplate))
}

const loadAllBalanceNoteSpecs = (balanceSheetTemplate) => {
  return balanceNoteSpecs.map((balanceNoteSpec) =>
    loadBalanceNoteSpec(balanceNoteSpec.balanceXbrlName, balanceSheetTemplate)
  )
}

const getBalanceSheetPostsWithNotes = () => {
  return balanceNoteSpecs.map((balanceNoteSpec) => balanceNoteSpec.balanceXbrlName)
}

export {
  loadBalanceNoteSpec,
  loadVisibleBalanceNoteSpecs,
  loadAllBalanceNoteSpecs,
  notBalancePostVisible,
  getBalanceSheetPostsWithNotes,
}
